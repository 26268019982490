import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMain = _resolveComponent("FilterMain")!

  return (_openBlock(), _createBlock(_component_FilterMain, {
    id: "main_view-product-sales-list",
    routeNameToRedirect: `${+_ctx.userRoleLevel >= 600 ? '' : 'storeList'}`,
    goToBackBtnDesc: `${+_ctx.userRoleLevel >= 600 ? '' : 'Ir para Lojas'}`,
    requiredFields: +_ctx.userRoleLevel >= 600 ? [] : ['store']
  }, null, 8, ["routeNameToRedirect", "goToBackBtnDesc", "requiredFields"]))
}