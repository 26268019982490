
import { defineComponent, onBeforeMount, ref } from "vue";
import FilterMain from "@/layout/header/partials/filters/FilterMain.vue";
import { useAuthStore } from "@/store/AuthStore";

export default defineComponent({
  name: "FilterMainRackViewProductSalesList",
  components: { FilterMain },
  setup() {
    const authStore = useAuthStore()
    const userRoleLevel = ref("")

    onBeforeMount(() => {
      if(authStore && authStore.getUser && authStore.getUser.role && authStore.getUser.role.level)
        userRoleLevel.value = authStore.getUser.role.level
    })

    return { userRoleLevel }
  }
});
